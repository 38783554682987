import { createAction, props } from '@ngrx/store';

import { DataZoomEvent } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.component';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import {
  IGetDemandImpactsChartDataParams,
  IGetDemandsByProxyParams,
  IGetDemandsChartDataParams,
} from '@/app/@core/entity/demand.service';
import { Currency, RUN_RATE_PERIODS } from './demand-planning.utils';

// Copied from backend
export interface IDemandTreeNode {
  key: string;
  label: string;
  data: Array<number | null>;
  children?: IDemandTreeNode[];
}

export interface IDemandFillingData {
  actualColumns: string[];
  lastPlanColumns: string[];
  hasGapBeforeStartOfFuture: boolean;
  hasGapBetweenActualCurrent: boolean;
}

type Periods = typeof RUN_RATE_PERIODS[number];

export type IRunRates = {
    [K in Periods]?: number | undefined;
};

export interface IDemandChartResponse {
  columns: string[];
  rows: Array<[string, Array<number | null>]>;
  tree: IDemandTreeNode[];
  fillingData?: IDemandFillingData;
  runRates?: IRunRates;
  hashEvents?: string;
}

export interface IDemandActualXResponse {
  columns: Array<[string, Array<string | null>]>;
  rows: Array<[string, Array<number | null>]>;
  tree: IDemandTreeNode[];
}

export const DEFAULT_DEMAND_CHART_RESPONSE: IDemandChartResponse = {
  columns: [],
  rows: [],
  tree: [],
};

export const DEFAULT_DEMAND_ACTUAL_X_RESPONSE: IDemandActualXResponse = {
  columns: [],
  rows: [],
  tree: [],
};

export interface IReducedHistoricalDemandByProxy {
  CustomerRef? :string;
  ProductID? : string;
}

export const UPDATE_DATE_ZOOM = createAction(
  '[🤖 Demand Chart] Update DataZoom',
  props<{ zoom: DataZoomEvent }>(),
);

export const UPDATE_DEMAND_GROUPING = createAction(
  '[🌱 Demand Planning Page] Update Demand Segment Grouping',
  props<{ data: string[] }>(),
);

export const UPDATE_KPI_TRACKER_GROUPING = createAction(
  '[🌱 Demand Planning Page] Update KPI Tracker Segment Grouping',
  props<{ data: string[] }>(),
);

export const UPDATE_DEMAND_DATASET_YEAR_GROUPING = createAction(
  '[🌱 Demand Planning Page] Update Demand Dataset Year Grouping',
  props<{ data: string[] }>(),
);

export const UPDATE_KPI_TRACKER_DATASET_YEAR_GROUPING = createAction(
  '[🌱 Demand Planning Page] Update KPI Tracker Dataset Year Grouping',
  props<{ data: string[] }>(),
);

export const UPDATE_DEMAND_ANALYTIC_GROUPING = createAction(
  '[🌱 Demand Planning Page] Update Demand Analytic Grouping',
  props<{ data: string[] }>(),
);

export const UPDATE_KPI_TRACKER_ANALYTIC_GROUPING = createAction(
  '[🌱 Demand Planning Page] Update KPI Tracker Analytic Grouping',
  props<{ data: string[] }>(),
);

export const UPDATE_DATE_AGGREGATION = createAction(
  '[🤖 Demand Planning Page] Update Date Aggregation',
  props<{ dateAggregation: DateAggregationOption }>(),
);

export const UPDATE_UOM = createAction(
  '[🤖 Demand Planning Page] Update Unit of Measure',
  props<{ uom: string }>(),
);

export const SELECT_EVENT_VERSIONS = createAction(
  '[🌱 Demand Planning Page] Selected Event Versions changed',
  props<{ ids: string[] }>(),
);

export const LOAD_DEMAND_CHART_DATA = createAction(
  '[⚡ Demand Planning Page] Load Demand Chart data',
  props<{ params: IGetDemandsChartDataParams }>(),
);
export const LOAD_DEMAND_CHART_DATA_SUCCESS = createAction(
  '[🤖 Demand Planning Page] Load Demand Chart data success',
  props<{ data: IDemandChartResponse }>(),
);

export const LOAD_DEMAND_CHART_DATA_FAILED = createAction(
  '[🤖 Demand Planning Page] Load Demand Chart data failed',
  props<{ error: any }>(),
);

export const LOAD_DEMAND_MULTI_ACTUAL_X_DATA = createAction(
  '[⚡ Demand Planning Page] Load Demand Multi Actual X data',
);

export const LOAD_DEMAND_MULTI_ACTUAL_X_DATA_SUCCESS = createAction(
  '[🤖 Demand Planning Page] Load Demand Multi Actual X data success',
  props<{ data: IDemandActualXResponse }>(),
);

export const LOAD_DEMAND_MULTI_ACTUAL_X_DATA_FAILED = createAction(
  '[🤖 Demand Planning Page] Load Demand Multi Actual X data failed',
  props<{ error: any }>(),
);

export const LOAD_DEMAND_ACTUAL_X_DATA = createAction(
  '[⚡ Demand Planning Page] Load Demand Actual X data',
  props<{ offset: number }>(),
);

export const UNLOAD_DEMAND_ACTUAL_X_DATA = createAction(
  '[⚡ Demand Planning Page] Unload Demand Actual X data',
  props<{ key: string }>(),
);

export const LOAD_DEMAND_ACTUAL_X_DATA_SUCCESS = createAction(
  '[🤖 Demand Planning Page] Load Demand Actual X data success',
  props<{ data: IDemandActualXResponse }>(),
);

export const LOAD_DEMAND_ACTUAL_X_DATA_FAILED = createAction(
  '[🤖 Demand Planning Page] Load Demand Actual X data failed',
  props<{ error: any }>(),
);

export const LOAD_DEMAND_IMPACTS_CHART_DATA = createAction(
  '[⚡ Demand Planning Page] Load Demand Impacts Chart data',
  props<{ params: IGetDemandImpactsChartDataParams }>(),
);

export const LOAD_DEMAND_IMPACTS_CHART_DATA_SUCCESS = createAction(
  '[🤖 Demand Planning Page] Load Demand Impacts Chart data success',
  props<{ data: IDemandChartResponse }>(),
);
export const LOAD_DEMAND_IMPACTS_CHART_DATA_FAILED = createAction(
  '[🤖 Demand Planning Page] Load Demand Impacts Chart data failed',
  props<{ error: any }>(),
);

export const LOAD_DEMAND_BY_PROXY_DATA = createAction(
  '[⚡ Demand Planning Page] Load Demand by Proxy data',
  props<{ params: IGetDemandsByProxyParams }>(),
);
export const LOAD_DEMAND_BY_PROXY_DATA_SUCCESS = createAction(
  '[🤖 Demand Planning Page] Load Demand by Proxy data success',
  props<{ data: IReducedHistoricalDemandByProxy[] }>(),
);

export const LOAD_DEMAND_BY_PROXY_DATA_FAILED = createAction(
  '[🤖 Demand Planning Page] Load Demand by Proxy data failed',
  props<{ error: any }>(),
);

export const OPEN_FORECAST_SETTINGS_DIALOG = createAction(
  '[🤖 Demand Planning Page] Open Forecast Settings Dialog',
);
export const FETCH_FORECAST_EXPLORER_TASKS = createAction(
  '[🤖 Demand Planning Page] Fetch Forecast Explorer Tasks',
);
export const FETCH_FORECAST_REPORT_TASKS = createAction(
  '[🤖 Demand Planning Page] Fetch Forecast Report Tasks',
);

export const SET_DECIMAL_PLACES = createAction(
  '[🤖 Demand Planning Page] Set Decimal Places',
  props<{ decimalPlaces: number }>(),
);
export const SET_NUMERIC_SCALE = createAction(
  '[🤖 Demand Planning Page] Set Numeric Scale',
  props<{ numericScale: string }>(),
);

export const UPDATE_DEMAND_LOADED_PARAMS = createAction(
  '[🤖 Demand Planning Page] Update Demand loaded params',
  props<{ params: IGetDemandsChartDataParams }>(),
)

export const UPDATE_IMPACTS_LOADED_PARAMS = createAction(
  '[🤖 Demand Planning Page] Update Impacts loaded params',
  props<{ params: IGetDemandImpactsChartDataParams }>(),
);

export const TRIGGER_LOAD_DEMAND = createAction(
  '[🤖 Demand Planning Page] Trigger load Demand',
)

export const TRIGGER_LOAD_DEMAND_IMPACT = createAction(
  '[🤖 Demand Planning Page] Trigger load Demand Impact',
)

export const LOAD_LIST_CURRENCIES = createAction(
  '[🤖 Demand Planning Page] Load List Currencies',
);

export const LOAD_LIST_CURRENCIES_SUCCESS = createAction(
  '[🤖 Demand Planning Page] Load List Currencies success',
  props<{ data: Currency[] }>(),
);

export const LOAD_LIST_CURRENCIES_FAILED = createAction(
  '[🤖 Demand Planning Page] Load List Currencies failed',
  props<{ error: any }>(),
);
