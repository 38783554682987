import { Injectable } from '@angular/core';
import { HttpService } from '../common/api/http.service';

export interface ForecastBaseTask {
  id: string;
  name: string;
  entity_id: string;
  state: string;
  error?: string;
}

export interface DemandsShaperTask {
  id: string;
  name: string;
  entity_id: string;
  state: string;
  error?: string;
}

/** Simplified service for triggering and monitoring end-to-end runs. */
@Injectable()
export class DemandGenerationService {
  private readonly apiController: string = 'simcel-async/demand-generation';

  constructor(private readonly http: HttpService) { }

  fetchPendingForecastBaseTasks() {
    return this.http.get(`${this.apiController}/pending-forecast-base-tasks`);
  }

  fetchPendingDemandsShaperTasks(planId: string, scenarioId: string) {
    return this.http.get(`${this.apiController}/pending-demands-shaper-tasks?planId=${planId}&scenarioId=${scenarioId}`);
  }
}
